import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import NavBarListingDetails from "../NAVBAR/NavBar.listings";
import ProductFilter from "./product-filter";
import ProductItemDetail from "./product-item-detail";


const ProductDetails = () => {
  const { name } = useParams();
  console.log(name);

  return (
    <>
      <Helmet>
        <title>
          Rentals - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className="bg-[#F9F9F9]">
        <NavBarListingDetails />
        <ProductItemDetail />
      </section>
    </>
  );
};

export default ProductDetails;
