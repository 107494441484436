import { createSlice } from '@reduxjs/toolkit';

const rentalFilter = createSlice({
    name: "rentalFilter",  // Changed to lowercase
    initialState: {
        data: null,
        loading: false,
        filter: {
            location: '',
            rooms: '',
            duration: '',
        },
        error: null,
        status: 'idle', // 'idle', 'pending', 'fulfilled', 'failed'
    }, 
    reducers: {
        setLocationFilter: (state, action) => {
            state.filter.location = action.payload;
        },
        setRoomsFilter: (state, action) => {
            state.filter.rooms = action.payload;
        },
        setDurationFilter: (state, action) => {
            state.filter.duration = action.payload;
        },
    }
});

export const { setLocationFilter, setRoomsFilter, setDurationFilter } = rentalFilter.actions;
export default rentalFilter.reducer;
