import React from "react";

import { Helmet } from "react-helmet";
import featured from "../../assets/images/energy-tariff.png";
import Footer from "../../components/FOOTER/Footer";
import JoinTheBest from "../../components/HOMEPAGE/JoinTheBest";

const MobileUpdatePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Updates - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className=" w-full bg-white p-1 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 my-10 xl:my-[20px] lg:pb-11 relative z-[1] ">
          <div className="space-y-8 sm:max-w-[300px] md:max-w-full lg:max-w-full lg:p-5  cursor-pointer group">
            <div className="space-y-3 md:space-y-2.5 mt-4">
              <p className="font-sor font-semibold text-new-black-950 capitalize text-2xl">
                Visitor's Booking is not enabled in your Community
              </p>

              <div className="flex  mt-5  md:mt-0">
                <div className="px-2 py-2.5 flex items-center space-x-3  font-sora text-xs text-new-black-400 font-medium">
                  <div className="w-2 h-2 bg-green-200 rounded-full"> </div>
                  <div>2 min read</div>
                </div>
              </div>

              <div className="h-[1px] w-full md:hidden bg-grey-200 my-[50px] "></div>

              <div className=" h-[200px] md:h-[300px] overflow-hidden rounded-[6.5px] md:pt-6 relative w-full">
                <img
                  src={featured}
                  alt=""
                  className=" md:object-cover object-contain w-full h-full absolute inset-0 "
                />
              </div>
            </div>
            <div className="">
              <div className="space-y-1 md:space-y-4 mt-0 md:mt-4">
                <div className="text-sm  space-y-5 leading-[22px] font-inter text-new-grey-500">
                  <p className=" text-[16px] font-inter">
                    Contact your Facility Manager or Join a community to enable
                    Visitors Booking for your community today!
                  </p>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to join a community
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      Open the sidebar menu on your Venco app and click on “My
                      Profile & IDs” and select “My Profile".
                    </li>
                    <li className="ml-4">
                      Click on the "Join Now" option under the “Request to join
                      a community” section.
                    </li>
                    <li className="ml-4">
                      Search and select your community by typing the name of
                      your estate and house number.
                    </li>
                    <li className="ml-4">Click the "Join Now" button.</li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    Benefits of Visitors’ Booking
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      Get secured codes between you and your visitor.
                    </li>
                    <li className="ml-4">
                      You can give access without getting manual calls from the
                      gate.
                    </li>
                    <li className="ml-4">
                      Give your guests access from wherever you are without
                      having to walk to the gate.
                    </li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to Book a Visitor
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4"> Click on Book Visitors.</li>
                    <li className="ml-4">
                      You can book a new visitor by clicking on a contact in
                      your phonebook and sending them an invite code.
                    </li>
                    <li className="ml-4">
                      Or Click on the “+” button to enter the visitor’s name and
                      phone number and select book visit
                    </li>
                    <li className="ml-4">
                      Repeat visits can also be booked by turning on the repeat
                      visit icon. Select the frequency, start date, end date and
                      Time of visit.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinTheBest />
      <Footer />
    </>
  );
};

export default MobileUpdatePage;
