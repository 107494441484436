import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { addDays } from "date-fns";
import Separator from "../../assets/svg/separator.svg";

const rentPeriod = "year";
const basePricePerYear = 250;
const VATPercentage = 0.2; // 20%
const platformFee = 200;

const NGN = "₦";

const ProductPaymentCard = (props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    addDays(new Date(), rentPeriod === "year" ? 1 : 365)
  );

  const { propertyUnitDetail } = props;
  const { amount, currency, vat } = propertyUnitDetail || {};

  const [duration, setDuration] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (propertyUnitDetail && amount && duration && vat) {
      const pricePerYear = amount * duration;
      const totalPriceWithoutVAT = pricePerYear + platformFee;
      const VAT = totalPriceWithoutVAT * (vat/100);
      const total = totalPriceWithoutVAT + VAT;
      setTotalPrice(total);
    } else {
    
      console.error("Some necessary data is missing for calculation");
    }
  }, [propertyUnitDetail, amount, duration]);

  const handleDurationChange = (e) => {
    setDuration(parseInt(e.target.value));
  };
  // const handleCardClick = () => {
  //   navigate(`/rental/{NGN}{props.id}`);
  // };

  // const handleStartDate = () => {
  //   // customDateRangePickerRef?.current?.show();
  //   props.showDatePicker(startDate, (value) => setStartDate(value));
  // };

  // const handleEndDate = () => {
  //   // customDateRangePickerRef?.current?.show();
  //   props.showDatePicker(endDate, (value) => setEndDate(value));
  // };

  // const getStartDate = () => {
  //   if (rentPeriod === "night") {
  //     return moment(startDate).format("DD/MM/YYYY");
  //   }
  //   return moment(startDate).format("MMM YYYY");
  // };

  // const getEndDate = () => {
  //   if (rentPeriod === "night") {
  //     return moment(endDate).format("DD/MM/YYYY");
  //   }
  //   return moment(endDate).format("MMM YYYY");
  // };

  return (
    <>
      <div
        className="bg-[#F8F8F7] w-full border-gray-400 border rounded-[20px] relative"
        onClick={() => []}
      >
        <div className="w-full flex px-6 py-4 items-center space-y-3 flex-col ">
          <div className="w-full pb-2 border-b flex items-center space-x-4 border-gray-400">
            <span className="font-bold flex pr-2 text-[20px]">
              {`${currency} ${Number(amount).toLocaleString()}`}
            </span>
            {` ${rentPeriod === "night" ? "/ night" : "/ year"}`}
          </div>
          {/* <div className="w-full pb-2 border-b flex items-center justify-between border-gray-400">
            <div onClick={handleStartDate} className="py-1 space-y-1">
              <label
                htmlFor="departureDate"
                className="mr-2 font-semibold text-[14px]"
              >
                Check In:
              </label>
              <p
                className="font-semibold underline"
                style={{ color: "#220E5B" }}
                onClick={handleStartDate}
              >
                {getStartDate()}
              </p>
            </div>

            <div className="h-full">
              <img src={Separator} alt="" />
            </div>

            <div onClick={handleEndDate} className="py-1 space-y-1">
              <label
                htmlFor="arrivalDate"
                className="mr-2 font-bold text-[12px]"
              >
                Check Out:
              </label>
              <p
                className="font-semibold underline"
                style={{ color: "#220E5B" }}
                onClick={handleEndDate}
              >
                {getEndDate()}
              </p>
            </div>
          </div> */}

          <div className="w-full pb-4 border-b flex  border-gray-400">
            <div className=" w-full flex flex-col py-1 space-y-1">
              <label
                htmlFor="departureDate"
                className="font-semibold pl-[2px] font-sora text-[14px]"
              >
                Duration:
              </label>
              <select
                id="rooms"
                className="w-full bg-transparent text-[18px] font-semibold text-sm bg-transparent text-gray-800"
                value={duration}
                onChange={handleDurationChange}
              >
                <option
                  className="font-sora w-full text-[18px]"
                  value=""
                  disabled
                  selected
                >
                  Select number of years
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                {/* Add more options as needed */}
              </select>
            </div>
          </div>
          {/* <div className="w-full pt-4 pb-6 border-b flex border-gray-400">
            <Link
              to="/get-started"
              className="py-[10px] items-center flex justify-center w-full px-4 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-[10px] font-semibold duration-300 transition-all hidden lg:flex self-center lg:text-base "
            >
              Book Property
            </Link>
          </div> */}
          <div className="w-full pb-2 pt-4 border-b border-gray-400">
            <div className="w-full pb-2 flex justify-between items-center">
              <h3 className="font-semibold font-sora text-[14px]">
                {NGN}
                {Number(amount).toLocaleString()} x
                <span className="text-[13px] pl-1">{duration}</span>
                {` ${rentPeriod === "night" ? "nights" : "years"}`}
              </h3>
              <h3 className="font-semibold font-sora ">
                ₦{Number(amount * duration).toLocaleString()}
              </h3>
            </div>
            <div className="w-full pb-2 flex justify-between items-center">
              <h3 className="font-semibold font-sora text-[14px]">
                VAT - <span className="text-[13px] pl-1"> {vat}%</span>
              </h3>
              <h3 className="font-semibold font-sora ">
                {NGN}
                {(
                  totalPrice -
                  (amount * duration + platformFee)
                ).toLocaleString()}
              </h3>
            </div>
            <div className="w-full pb-2 flex justify-between items-center">
              <h3 className="font-semibold font-sora text-[14px]">
                Platform Fee
              </h3>
              <h3 className="font-semibold font-sora "> <span>{NGN}</span>200</h3>
            </div>
          </div>

          <div className="w-full pb-4 pt-4 border-b border-gray-400 flex justify-between items-center">
            <h3 className="font-semibold font-sora text-[14px]">Total</h3>
            <h3 className="font-semibold font-sora text-2xl">
              <span className="pr-0"> {NGN}</span>
             
              {Number(totalPrice).toLocaleString()}
            </h3>
          </div>

          <div className="w-full pt-4 pb-4 flex ">
            <Link
              to="https://calendar.app.google/u76vHfPQGh1hjDQS8"
              className="py-[10px] items-center flex justify-center w-full px-4 text-green-500 bg-light-green-500 hover:bg-light-green-600 rounded-[10px] font-semibold duration-300 transition-all hidden lg:flex self-center lg:text-base "
            >
              Schedule Visit
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPaymentCard;
