import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowCircleLeft, ArrowCircleRight, Star } from "phosphor-react";
import { useSwipeable } from 'react-swipeable';


const ProductCard = ({ amount, community_name, currency, name, location, gallery }) => {
  const navigate = useNavigate();
  const [mainImageIndex, setMainImageIndex] = useState(0);

  const handleCardClick = () => {
    navigate(`/rental/${name}`);
  };

  const handleNextImage = () => {
    setMainImageIndex((prevIndex) => (prevIndex + 1) % gallery.length);
  };

  const handlePreviousImage = () => {
    setMainImageIndex((prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length);
  };



  const handleSwipeLeft = () => {
    setMainImageIndex((prevIndex) => (prevIndex + 1) % gallery.length);
  };

  const handleSwipeRight = () => {
    setMainImageIndex((prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });
  

  return (
    <>
      <div
        className="max-w-[500px] bg-white shadow-lg cursor-pointer rounded-lg overflow-hidden m-4 relative"
        // onClick={handleCardClick}
      >
        <div className=" space-x-8 relative h-[230px] md:h-[230px] flex justify-center">
          <div className="w-full relative h-full" {...swipeHandlers}>
            <img
              className="object-cover w-[400px] h-full bg-gray-300"
              src={gallery[mainImageIndex]}
              alt="Product"
              onClick={handleCardClick}
            />

            <button
              className="absolute top-1/2 left-0 transform -translate-y-1/2"
              onClick={handlePreviousImage}
            >
              <ArrowCircleLeft fill="black" color="white" size={40} />
            </button>
            <button
              className="absolute top-1/2 right-0 transform -translate-y-1/2"
              onClick={handleNextImage}
            >
              <ArrowCircleRight fill="black" color="white" size={40} />
            </button>
          </div>
        </div>

        <div className="p-4 space-y-2" onClick={handleCardClick}>
          <div className="flex justify-between">
            <h2 className="text-sm font-semibold text-gray-800">
              {community_name}
            </h2>
            <h2 className="flex items-center justify-center">
              {/* <Star /> <span className="text-sm pl-1 font-semibold">4.7</span> */}
            </h2>
          </div>
          <p className="text-sm text-gray-600 mb-2">{location}</p>
          {/* <p className="text-[10px] text-gray-600 mb-2">{name}</p> */}
          <div className="flex items-center justify-between">
            <p className="text-sm font-bold">
              {currency} {Number(amount).toLocaleString()}{" "}
              <span className="text-[12px] font-normal">per year</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCard;
