import * as React from "react"
const Marker = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={34}
    viewBox="0 0 32 34" 
    fill="none"
    {...props}
  >
    <path
      fill="#FF0707"
      fillRule="evenodd"
      d="M16.398 31.81C18.68 30.68 30 24.55 30 14c0-7.732-6.268-14-14-14S2 6.268 2 14c0 10.55 11.32 16.68 13.602 17.81.255.126.54.126.796 0ZM16 20a6 6 0 1 0 0-12 6 6 0 0 0 0 12Z"
      clipRule="evenodd"
    />
  </svg>
)
export default Marker
