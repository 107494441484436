import { BASE_DOMAIN } from "./env"

export const get_property_unit_list = async (params) => {
    try {
        var url = `${BASE_DOMAIN}/api/method/community.v1.community.property_units.get_property_unit_list?`

        if (Boolean(params?.location?.length)) {
            url = url + `location=${params.location}&`
        }
        if (Boolean(params?.rooms?.length)) {
            url = url + `rooms=${params.rooms}&`
        }
        if (Boolean(params?.page?.length)) {
            url = url + `page=${params.page}&`
        }

        return await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            }
        })
    } catch (error) {
        throw error
    }
}