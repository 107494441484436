import React from "react";
import ProductCard from "./product-card";


const ProductListing = (props) => {
  return (
    <section
      className="mt-4 mb-4 xl:py-2 container mx-auto px-4 font-sora"
      id="productListings"
    >
      <div className="flex justify-center gap-14 md:gap-24 lg:gap-20 xl:gap-32 items-center flex-col md:flex-row">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {props.properties.map((property) => (
            <ProductCard key={property.name} {...property} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductListing;
