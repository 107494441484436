import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import ErrorStateImg from "../../assets/images/rentals/ErrorState.svg";
import EmptyStateImg from "../../assets/images/rentals/EmptyState.svg";

import Footer from "../../components/FOOTER/Footer";

import NavBarRentals from "../../components/NAVBAR/Navbar.rentals";
import HeroVariationRentals from "../../components/HERO/HeroVariationRentals";
import ProductListing from "../../components/RENTALS/product-listings";
import { get_property_unit_list } from "../../api/rentals";
import Loading from "../../components/Loading";

const RentersHomePage = () => {
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState([]);
  const [filters, setFilters] = useState({});
  const [dataFetchFailed, setDataFetchFailed] = useState(false);
  const [atLastPage, setAtLastPage] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setUpPage();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading]);

  const setUpPage = async (params = {}) => {
    try {
      setCurrentPage(1);
      setFilters(params);
      var params = {
        page: 1,
        ...params,
      };
      setLoading(true);
      fetchProperties(params);
    } catch (error) {}
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      loading ||
      loadingMore ||
      atLastPage
    ) {
      return;
    }
    setLoadingMore(true);
    var params = {
      page: currentPage,
      ...filters,
    };
    fetchProperties(params);
    setLoadingMore(false);
  };

  const fetchProperties = async (params) => {
    try {
      const res = await get_property_unit_list(params);
      const response = await res.json();
      console.log("response", response);
      console.log("response", res.status);
      console.log("response", res.ok);
      if (res.ok) {
        console.log("hello world");
        setProperties(response.data);
        setCurrentPage(response.pagination.page);
        setAtLastPage(
          response.pagination.page ===
            response.pagination.total_pages
        );
        setDataFetchFailed(false);
      } else {
        console.log("omooooooo")
        setDataFetchFailed(true);
        setLoading(false);
        setErrMsg(response.message);
        toast.error("Something went wrong", {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          zIndex: 1000,
        });
      }
    } catch (error) {
      console.log(error.message)
      setDataFetchFailed(true);
      setLoading(false);
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        zIndex: 1000,
      });
      return;
    }
    setLoading(false);
  };

  const LoadMoreProperties = async () => {};

  return (
    <>
      <Helmet>
        <title>
          Rentals - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className="bg-white">
        <NavBarRentals />
        <HeroVariationRentals filterProperties={setUpPage} />
        {loading && (
          <div className="h-[420px] flex flex-col items-center justify-center">
            <Loading />
          </div>
        )}

        {dataFetchFailed && (
          <div className=" h-[420px] flex flex-col items-center justify-center">
            <img src={ErrorStateImg} className="h-[130px] w-[483px] " alt="" />
            <p className="font-sora pt-4 font-bold">
              {errMsg ? errMsg : "Error Fetching Data"}
            </p>
          </div>
        )}

        {!loading &&
          !dataFetchFailed &&
          (properties.length ? (
            <ProductListing
              LoadMoreProperties={LoadMoreProperties}
              properties={properties}
            />
          ) : (
            <div className=" h-[420px] flex flex-col items-center justify-center">
              <img
                src={EmptyStateImg}
                className="h-[130px] w-[483px] "
                alt=""
              />
              <p className="font-sora font-bold">No Results!</p>
            </div>
          ))}

        <Footer />
      </section>
    </>
  );
};

export default RentersHomePage;
