import React from "react";

import { Helmet } from "react-helmet";
import featured from "../../assets/images/energy-tariff.png";
import Footer from "../../components/FOOTER/Footer";
import JoinTheBest from "../../components/HOMEPAGE/JoinTheBest";

const BillsAndUtilityPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Updates - Venco | All-in-one Community Management Software
        </title>
        <meta
          property="og:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Updates - Venco | All-in-one Community Management Software"
        />
      </Helmet>

      <section className=" w-full bg-white p-1 relative overflow-hidden">
        <div className="container mx-auto px-4 md:px-8 my-10 xl:my-[20px] lg:pb-11 relative z-[1] ">
          <div className="space-y-8 sm:max-w-[300px] md:max-w-full lg:max-w-full lg:p-5  cursor-pointer group">
            <div className="space-y-3 md:space-y-2.5 mt-4">
              <p className="font-sor font-semibold text-new-black-950 capitalize text-2xl">
                Bills & Utility is not enabled in your community
              </p>

              <div className="flex  mt-5  md:mt-0">
                <div className="px-2 py-2.5 flex items-center space-x-3  font-sora text-xs text-new-black-400 font-medium">
                  <div className="w-2 h-2 bg-green-200 rounded-full"> </div>
                  <div>2 min read</div>
                </div>
              </div>

              <div className="h-[1px] w-full md:hidden bg-grey-200 my-[50px] "></div>

              <div className=" h-[200px] md:h-[300px] overflow-hidden rounded-[6.5px] md:pt-6 relative w-full">
                <img
                  src={featured}
                  alt=""
                  className=" md:object-cover object-contain w-full h-full absolute inset-0 "
                />
              </div>
            </div>
            <div className="">
              <div className="space-y-1 md:space-y-4 mt-0 md:mt-4">
                <div className="text-sm  space-y-5 leading-[22px] font-inter text-new-grey-500">
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Get around the hassles of fulfilling communal dues and
                    collections and also purchase utilities with just a few
                    clicks. Venco makes it easy for you to manage your utility
                    usage, track utility purchase history and instantly get
                    electronic copies of utility & community bills.
                  </p>
                  <p className=" md:text-[16px] text-[14px] font-inter">
                    Contact your Facility Manager or Join a community to enable
                    Bills & Utility for your community today!
                  </p>

                  <p className="font-bold text-[14px] text-black capitalize">
                    How to pay communal dues and bills
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">Click on the “Join Now” button.</li>
                    <li className="ml-4">
                      Search and select your community by typing the name of
                      your estate and house number.
                    </li>
                    <li className="ml-4">Click the "Join Now" button.</li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    Benefits of Bills & Utility
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4">
                      You can pay communal bills easily when you get demand.
                    </li>
                    <li className="ml-4">
                      Buy utilities like electricity and gas from the comfort of
                      your home.
                    </li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to pay communal dues and bills
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4"> Click on Bills and Utilities.</li>
                    <li className="ml-4">Click on Pay Community Bills</li>
                    <li className="ml-4">
                      Click on demand notice placed against your property unit
                      and make payment.
                    </li>
                  </ul>
                  <p className="font-bold text-[14px] capitalize text-black">
                    How to buy utilities
                  </p>
                  <ul className="list-disc">
                    <li className="ml-4"> Click on Bills and Utilities.</li>
                    <li className="ml-4">
                      Click on Purchase Utilities and enter the amount of
                      Electricity or gas you want
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <JoinTheBest />
      <Footer />
    </>
  );
};

export default BillsAndUtilityPage;
