import GoogleMap from "google-maps-react-markers";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import ProductPaymentCard from "./product-payment-card";

import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import { addDays } from "date-fns";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import { Link, json, useParams } from "react-router-dom";

import ErrorStateImg from "../../assets/images/rentals/ErrorState.svg";

import CustomDatePicker from "../../components/DatePicker/PristineDatePicker";

import { ArrowCircleLeft, ArrowCircleRight } from "phosphor-react";
import Marker from "./Marker";
import Loading from "../Loading";
import { useSwipeable } from 'react-swipeable';

const rentPeriod = "year"; //'night' 'month' 'year'

const ProductItemDetail = (props, ref) => {
  const { name } = useParams();

  const url = `https://community.venco.africa/api/method/community.v1.community.property_units.get_property_unit_details?name=${name}`;

  const customDatePickerRef = useRef();
  const mapRef = useRef(null);

  const [date, setDate] = useState([new Date(), new Date()]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    addDays(new Date(), rentPeriod === "night" ? 1 : 365)
  );
  const [propertyUnitDetail, setPropertyUnitDetail] = useState(null);
  const [propertyUnitDetailError, setPropertyUnitDetailError] = useState(null);
  const [errMsg, setErrMsg] = useState("");
  const [dataFetchFailed, setDataFetchFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const [longitude, setLongitude] = useState(3.8892);
  const [latitude, setLatitude] = useState(6.7344);
  const [mapReady, setMapReady] = useState(false);

  console.log("longitude", longitude)
  console.log("latitude", latitude)

  const {
    address,
    amount,
    amenities,
    currency,
    gallery,
    geolocation,
    location,
    property_name,
    no_of_baths,
    no_of_beds,
    no_of_parking,
    community_name,
  } = propertyUnitDetail || {};

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        setLoading(false);
        const jsonData = await response.json();
        setPropertyUnitDetail(jsonData);
        console.log("Fetched data for product-item-details:", jsonData);
      } catch (error) {
        setLoading(false);
        setDataFetchFailed(true);
        // setErrMsg(error);
      }
    };
    fetchData();
  }, [name, url]);

  useEffect(() => {
    if (geolocation) {
      try {
        // Parse the JSON string into a JavaScript object
        const apiGeolocation = JSON.parse(geolocation);

        // Access the coordinates array
        const apiCoordinates = apiGeolocation.features[0].geometry.coordinates;

        // Extract latitude and longitude
        const latitude = apiCoordinates[1];
        const longitude = apiCoordinates[0];

        setLongitude(longitude);
        setLatitude(latitude);

      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      console.error("geolocationString is undefined or null");
    }
  }, [geolocation]);

  // useEffect(() => {
  //   console.log(date);
  // }, [date]);


  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [currentExtraImageIndex, setCurrentExtraImageIndex] = useState(0);

  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    setMapReady(true);
  };

  const coordinates = [
    { lat: latitude, lng: longitude, name: "Lagos", markerId: 1 },
  ];

  const ApiCoordinates = useEffect(() => {
    setMapReady(true);
  }, []);

  const onMarkerClick = (e, { markerId, lat, lng }) => {
    // console.log("This is ->", markerId);
    mapRef.current.setCenter({ lat, lng });
  };

  const handleStartDate = () => {
    customDatePickerRef.current?.show(startDate, (value) =>
      setStartDate(value)
    );
  };

  const handleEndDate = () => {
    customDatePickerRef.current?.show(endDate, (value) => setEndDate(value));
  };

  const getStartDate = () => {
    if (rentPeriod === "night") {
      return moment(startDate).format("DD/MM/YYYY");
    }
    return moment(startDate).format("MMM YYYY");
  };

  const getEndDate = () => {
    if (rentPeriod === "night") {
      return moment(endDate).format("DD/MM/YYYY");
    }
    return moment(endDate).format("MMM YYYY");
  };

  // const handleExtraImageClick = (image) => {
  //   // setMainImage(image);
  // };

  // const handleNextImage = () => {
  //   setCurrentExtraImageIndex(
  //     (prevIndex) => (prevIndex + 1) % extraImages.length
  //   );
  //   // setMainImage(extraImages[currentExtraImageIndex]);
  // };

  // const handlePreviousImage = () => {
  //   setCurrentExtraImageIndex(
  //     (prevIndex) => (prevIndex - 1 + extraImages.length) % extraImages.length
  //   );
  //   // setMainImage(extraImages[currentExtraImageIndex]);
  // };

  const handleExtraImageClick = (index) => {
    setMainImageIndex(index);
  };

  const handleNextImage = () => {
    setMainImageIndex((prevIndex) => (prevIndex + 1) % gallery.length);
  };

  const handlePreviousImage = () => {
    setMainImageIndex(
      (prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length
    );
  };

  const handleSwipeLeft = () => {
    setMainImageIndex((prevIndex) => (prevIndex + 1) % gallery.length);
  };

  const handleSwipeRight = () => {
    setMainImageIndex((prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
  });
  

  const mainImage =
    gallery && gallery.length > 0 ? gallery[mainImageIndex] : null;
  const extraImages = gallery ? gallery.slice(1) : [];

  return (
    <>
      {loading && (
        <div className="h-[420px] flex flex-col items-center justify-center">
          <Loading />
        </div>
      )}

      {dataFetchFailed && (
        <div className=" bg-white h-[80vh] w-full flex flex-col items-center justify-center">
          <img src={ErrorStateImg} className="h-[130px] w-[483px] " alt="" />
          <p className="font-sora pt-4 font-bold">
            {errMsg ? errMsg : "Error Fetching Data"}
          </p>
        </div>
      )}

      {!loading && !dataFetchFailed && (
        <div className="container  box-border mx-auto">
          <div className="w-full px-4 relative gap-8 flex justify-center pt-[50px] pb-[30px]">
            {/* Scrollable container for the left content */}
            <div className="md:w-[945px] box-border w-screen flex max-h-[80vh]  flex-col overflow-y-auto no-scrollbar">
              {/* Content of the scrollable green div */}
              <div className="scrolling-div box-border pb-10 rounded-[20px] space-y-8 ">
                <div className=" space-x-8 relative h-[391px] md:h-[450px] flex justify-center w-3/3">
              
                    <div className="w-full relative h-full" {...swipeHandlers}>
                      <img
                        className="main-image cursor-pointer rounded-md bg-gray-300 object-cover w-full h-full"
                        src={mainImage}
                      />
                      <button
                        className="absolute top-1/2 left-0 transform -translate-y-1/2"
                        onClick={handlePreviousImage}
                      >
                        {" "}
                        <ArrowCircleLeft
                          fill="black"
                          color="white"
                          size={40}
                        />{" "}
                      </button>
                      <button
                        className="absolute top-1/2 right-0 transform -translate-y-1/2"
                        onClick={handleNextImage}
                      >
                        <ArrowCircleRight
                          fill="black"
                          color="white"
                          size={40}
                        />
                      </button>
                    </div>

                  <div className="flex w-[147px] hidden md:flex h-[450px] overflow-y-auto no-scrollbar flex-col">
                    <div className="max-h-[800px] space-y-4">
                      {extraImages.map((image, index) => (
                        <img
                          key={index}
                          className="extra-image cursor-pointer rounded-md bg-gray-300 object-cover w-full h-[147px]"
                          src={image}
                          onClick={() => handleExtraImageClick(index + 1)}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="w-full space-y-2">
                  <h1 className="font-bold font-sora md:text-[32px] text-[26px]">
                    {property_name}
                  </h1>

                  <ul className="flex text-md space-x-8">
                    <li className=" pr-4 ">{no_of_beds} Rooms</li>{" "}
                    <p className="pt-1">*</p>
                    <li className=" pr-4 ">{no_of_baths} Baths</li>
                    <p className="pt-1">*</p>
                    <li className="">{no_of_parking} Parking</li>
                  </ul>
                </div>

                <div className="w-full space-y-[4px]">
                  <h1 className="font-semibold font-sora text-[24px]">
                    {community_name}
                  </h1>
                  <p>
                    {address}, {location}
                  </p>
                  <p></p>
                </div>

                <div className="w-full space-y-[4px]">
                  <h1 className="font-semibold font-sora  text-[24px]">
                    {" "}
                    Offerings
                  </h1>
                  <div class="grid grid-cols-2 md:grid-cols-2">
                    {amenities &&
                      amenities.map((amenity, index) => (
                        <div key={index} className="flex flex-col">
                          <div className="flex flex-col">
                            <div className="py-2">{amenity}</div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="w-full space-y-4">
                  <div className="grid space-y-10 md:space-y-0 grid-cols-1 sm:grid-cols-2">
                    <div className="col-span-1 sm:col-span-1">
                      <div className="flex flex-col">
                        <h1 className="font-semibold text-[24px] font-sora  ">
                          House Rules
                        </h1>
                        <div className="py-2">
                          No smoking inside the premises.
                        </div>
                        <div className="py-2">Pets are not allowed.</div>
                        <div className="py-2">
                          Quiet hours are between 10 PM and 7 AM.
                        </div>
                        <div className="py-2">
                          Guests are responsible for damages.
                        </div>
                        <div className="py-2">
                          Please respect the neighbors.
                        </div>
                      </div>
                    </div>
                    <div className="col-span-1 sm:col-span-1">
                      <div className="flex flex-col">
                        <h1 className="font-semibold text-[24px] sm:text-2xl">
                          Cancellation Policy
                        </h1>
                        <div className="py-2">
                          Cancel before check-in date 20 Oct for a partial
                          refund of up to 80% of the total amount paid.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-full h-[500px] relative overflow-hidden rounded-[20px]">
                  <h1 className="font-semibold text-[24px] font-sora  mb-4">
                    Location
                  </h1>
                  {/* <CustomDateRange ref={customDateRangePickerRef} /> */}
                  <CustomDatePicker ref={customDatePickerRef} />

                  {mapReady && (
                    <GoogleMap
                      apiKey="AIzaSyCbuL3q5aQJkAbtUf9DWDRZUgu4MlPsqG0"
                      defaultCenter={{ lat: latitude, lng: longitude }}
                      defaultZoom={13}
                      mapMinHeight="500px"
                      onGoogleApiLoaded={onGoogleApiLoaded}
                      onChange={(map) => console.log("Map moved", map)}
                    >
                      {coordinates.map(({ lat, lng, name }, index) => (
                        <Marker
                          key={index}
                          lat={lat}
                          lng={lng}
                          markerId={name}
                          onClick={onMarkerClick}
                        />
                      ))}
                    </GoogleMap>
                  )}
                </div>
              </div>
            </div>

            {/* Fixed-width container for the payment card */}
            <div className=" absolute sticky lg:flex hidden r-0 w-[400px] h-full ">
              <ProductPaymentCard
                propertyUnitDetail={propertyUnitDetail}
                showDatePicker={customDatePickerRef.current?.show}
              />
            </div>
          </div>

          <div
            className="absolute sticky lg:hidden block bottom-0 left-0 right-0 px-4 pt-2 pb-4 bg-white shadow-md"
            style={{ boxShadow: "0px 4px 6px -1px rgba(0,0,0,0.1)" }}
          >
            <div className="flex justify-between items-center">
              <div className="gap-y-1 space-y-1">
                <p className="font-semibold text-lg">{`${currency} ${Number(
                  amount
                ).toLocaleString()} / ${
                  rentPeriod === "night" ? "night" : "year"
                }`}</p>
                <div className=" flex text-[16px]">
                  <p
                    className="font-semibold underline"
                    style={{ color: "#220E5B" }}
                    onClick={handleStartDate}
                  >
                    {getStartDate()}
                  </p>
                  <p className="px-2">-</p>
                  <p
                    className="font-semibold underline"
                    style={{ color: "#220E5B" }}
                    onClick={handleEndDate}
                  >
                    {getEndDate()}
                  </p>
                </div>
              </div>

              <Link
                to="https://calendar.app.google/u76vHfPQGh1hjDQS8"
                className="py-3 px-6 text-green-500 bg-light-green-500 hover:bg-light-green-500 rounded-lg font-bold duration-300 text-center transition-all  inline-flex justify-center "
              >
                Schedule a Visit
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductItemDetail;
