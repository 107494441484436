import React from "react";
import NavBarDark from "../components/NAVBAR/NavBarDark";
import OnboardingForm from "../components/OnbaordingForm";
import Footer from "../components/FOOTER/Footer";
import { Helmet } from "react-helmet";
import { RiCheckFill } from "react-icons/ri";
import formPageBg from "../assets/svg/formPageBg.svg";

const OnboardingPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Sign up as a referral - Venco | All-in-one Community Management
          Software
        </title>
        <meta
          property="og:title"
          content="Sign up as a referral - Venco | All-in-one Community Management Software"
        />
        <meta
          name="twitter:title"
          content="Sign up as a referral - Venco | All-in-one Community Management Software"
        />
      </Helmet>
      <NavBarDark />
      <section className="container mx-auto my-10 xl:py-[100px] p-4">
        <div className="flex flex-col md:flex-row gap-7 h-full  xl:max-w-[1328px] xl:mx-auto">
          {/* Form Image Section */}
          <div className="bg-green-500 h-[242px] md:h-auto  relative overflow-hidden px-6 py-10 rounded-lg max-w-[595px] md:w-2/5 flex flex-col md:py-[100px] lg:py-[180px] lg:px-10 justify-center md:justify-start">
            <p className="text-xl font-sora font-semibold text-light-green-500 text-center md:text-[36px] md:leading-[42px] xl:text-[42px] xl:leading-[54px] relative z-[1]">
              Let’s get your community started!
            </p>
            <div className="h-[30px] lg:h-24"></div>
            <div className="flex gap-2.5 items-center justify-center relative z-[1]">
              <div className="h-4 w-4 rounded-full center bg-light-green-500">
                <RiCheckFill className="text-sm" />
              </div>
              <p className="text-white text-sm">
                Refer a community or an Estate
              </p>
            </div>
            <div className="absolute left-0">
              <img src={formPageBg} alt="" />
            </div>
          </div>
          <OnboardingForm />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OnboardingPage;
