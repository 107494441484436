import { configureStore } from "@reduxjs/toolkit";
import blogReducer from "./features/blogSlice";
import countryReducer from "./features/countrySlice";
import filterReducer from './features/rentalfilterSlice'
const store = configureStore({
    reducer: {
        blogs: blogReducer,
        geoDetails: countryReducer,
        rentalFilter: filterReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    }).concat()
})


export default store;