import { AnimatePresence } from "framer-motion";

import "react-date-range/dist/styles.css"; 
import "react-date-range/dist/theme/default.css"; 
import { Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import HOME from "./pages/HOME";
import ResidentsPage from "./pages/RESIDENTSPage";


import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";

import ADMIN from "./pages/ADMIN";
import SECURITYPage from "./pages/SECURITYPage";
import SmartMeterPage from "./pages/SmartMeterPage";
import BLOGPages from "./pages/BLOGPages";
import FAQPage from "./pages/FAQPage";
import ABOUTPage from "./pages/ABOUTPage";
import PrivacyPage from "./pages/PrivacyPage";
import TermsPage from "./pages/TermsPage";
import Cookies from "./components/Cookies";
import BlogIndividualPage from "./pages/BlogIndividualPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchBlogs } from "./app/features/blogSlice";
import ContactPage from "./pages/ContactPage";
import { fetchCountry } from "./app/features/countrySlice";
import PageNotFound from "./pages/PageNotFound";
// import FloatingDownloadButton from "./components/BUTTONS/FloatingDownloadButton";
import GetStartedPage from "./pages/GetStartedPage";
import ResidentsHowItWorks from "./pages/HowItWorksPages/ResidentsHowItWorks";
import Referrer from "./pages/Referrer";
import ReferralFormPage from "./pages/ReferralFormPage";
import OnboardingPage from "./pages/OnboardingPage";
import ReferCommunityPage from "./pages/ReferCommunityPage";
import AdminHowItWorks from "./pages/HowItWorksPages/AdminHowItWorks";
import SecurityHowItWorks from "./pages/HowItWorksPages/SecurityHowItWorks";
import MobileUpdatePage from "./pages/mobile-updates/VisitorsBooking.jsx";
import BillsAndUtilityPage from "./pages/mobile-updates/BillsandUtility.jsx";
import IssuesReportingPage from "./pages/mobile-updates/IssuesReporting.jsx";
import AmenitiesBookingPage from "./pages/mobile-updates/AmenitiesBooking.jsx";
import EmergencyReportingPage from "./pages/mobile-updates/EmergencyReporting.jsx";
import DashboardPage from "./pages/mobile-updates/DashboardPage.jsx";
import RentersHomePage from "./pages/renters/HomePage.jsx";
import ProductDetails from "./components/RENTALS/product-details.jsx"

function App() {
  const location = useLocation();

  AOS.init({
    // Global settings:
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
    initClassName: "aos-init", // class applied after initialization
    animatedClassName: "aos-animate", // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 700, // values from 0 to 3000, with step 50ms
    easing: "ease", // default easing for AOS animations
    once: false, // whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
  });

  const cookieAccepted = localStorage.getItem("cookieAccepted");
  const dispatch = useDispatch();
  const blogStatus = useSelector((state) => state.blogs.status);
  const geoDetailsStatus = useSelector((state) => state.geoDetails.status);

  // getBlogs
  useEffect(() => {
    if (blogStatus === "idle") {
      dispatch(fetchBlogs());
    }
    // eslint-disable-next-line
  }, [blogStatus]);

  // getCountry
  useEffect(() => {
    if (geoDetailsStatus === "idle") {
      dispatch(fetchCountry());
    }
    // eslint-disable-next-line
  }, [geoDetailsStatus]);

  return (
    <>
      {!cookieAccepted && <Cookies />}
      <div className="fixed top-[12vh] z-[1000]">
        <ToastContainer />
        {/* <FloatingDownloadButton /> */}
      </div>
      {
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<HOME />} />
            <Route path="/visitors-booking-is-disabled" element={<MobileUpdatePage />} />
            <Route
              path="/emergency-reporting-is-disabled"
              element={<EmergencyReportingPage />}
            />
            <Route
              path="/amenities-booking-is-disabled"
              element={<AmenitiesBookingPage />}
            />
            <Route path="/issues-reporting-is-disabled" element={<IssuesReportingPage />} />
            <Route path="/dashboard-is-disabled" element={<DashboardPage />} />
            <Route
              path="/bills-and-utility-is-disabled"
              element={<BillsAndUtilityPage />}
            />
             <Route path="/rentals" element={<RentersHomePage/>}/>
             <Route path="/rental/:name" element={<ProductDetails />} />

            <Route path="/products/residents" element={<ResidentsPage />} />
            <Route path="/products/admin" element={<ADMIN />} />
            <Route path="/products/security" element={<SECURITYPage />} />
            <Route path="/products/meters" element={<SmartMeterPage />} />
            <Route path="/blog" element={<BLOGPages />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/about-us" element={<ABOUTPage />} />
            <Route path="/contact-us" element={<ContactPage />} />
            <Route path="/privacy-policy" element={<PrivacyPage />} />
            <Route path="/terms-and-condition" element={<TermsPage />} />
            <Route path="/get-started" element={<GetStartedPage />} />
            <Route
              path="/how-it-works-for-residents"
              element={<ResidentsHowItWorks />}
            />
            <Route
              path="/how-it-works-for-admins"
              element={<AdminHowItWorks />}
            />
            <Route
              path="/how-it-works-for-security"
              element={<SecurityHowItWorks />}
            />
            <Route path="/referrer" element={<Referrer />} />
            <Route path="/refer-community" element={<ReferCommunityPage />} />
            <Route path="/signup-referral" element={<ReferralFormPage />} />

            <Route path="/onboard" element={<OnboardingPage />} />
            <Route path="/blog/:slug" element={<BlogIndividualPage />} />

            {/* 404 Page */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </AnimatePresence>
      }
    </>
  );
}

export default App;
