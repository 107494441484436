import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { addDays } from "date-fns";
import Modal from "react-modal";
import { Calendar } from "react-date-range";
import './Style.css'
import { X } from "phosphor-react";

const customStyles = {
  content: {
    borderRadius: 24,
    zIndex: 9999999999999999,
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.24)",
  },
};

const CustomDatePicker = forwardRef((props, ref) => {
  const [modalIsOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [onClose, setOnClose] = useState(undefined);


  const show = useCallback((currentDate, cb) => {
    console.log('hello');
    if (cb) {
      setOnClose(() => cb);
    }
    setDate(currentDate);
    setIsModalOpen(true);
  }, []);

  const hide = () => {
    setIsModalOpen(false);
  };

  useImperativeHandle(ref, () => ({
    hide: hide,
    show: show,
  }));

  return (
    <Modal
      isOpen={modalIsOpen}
      // onRequestClose={hide}
      style={customStyles}
      contentLabel="Select Date Range"
      contentElement={() => {
        return (
          <div className="custom-date-range-picker-modal">
            <div className="custom-date-range-picker-contents">
              <div className="custom-date-range-picker-contents-header">
                <p className="custom-date-range-picker-contents-header-title">Select Date</p>
                <div
                  className="custom-date-range-picker-close-btn"
                  onClick={hide}
                >
                  <X className="custom-date-range-picker-close-icon" />
                </div>
              </div>
              <Calendar
                date={date}
								onChange={(value) => setDate(value)}
                // months={1}
                rangeColors={["#6C8637"]}
                className="custom-date-range-picker"
              />
              <div className="custom-date-range-picker-ctas">
                <p
                  className="custom-date-range-picker-close-btn"
                  onClick={hide}
                >
                  Cancel
                </p>
                <p 
                  className='custom-date-range-picker-done-btn'                  
                  onClick={() => {
                    console.log('date', date);
                    if (Boolean(onClose)) {
                      // @ts-ignore
                      onClose(date);
                      
                    }
                    hide();
                  }}
                >
                  Done
                </p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
});

export default CustomDatePicker;
