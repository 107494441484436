import React, { useEffect, useState } from 'react'
import Search from "../../assets/svg/search.svg";

const ProductFilter = (props) => {
  const [filters, setFilters] = useState({
    location: '',
    rooms: '',
    duration: '',
  })

  const handleFilterChange = (e) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [e.target.name]: e.target.value,
    }))
  };

  const submitFilter = async () => {
    try {
      const res = await props.filterProperties(filters)
    } catch (error) {
      
    }
  }

  // useEffect(() => {
  //   console.log('Location:', filter.location);
  //   console.log('Rooms:', filter.rooms);
  //   console.log('Duration:', filter.duration);
  // }, [filter.location, filter.rooms, filter.duration]); 
  
  return (
    <div className="bg-green-500 font-sora min-w-[340px] border border-light-green-500 rounded-[20px]">
    <div className="px-4 py-6 md:py-2 w-full flex flex-col md:flex-row gap-4">
      <div className="md:border-light-green-500 md:border-r flex-grow">
        <label
          htmlFor="location"
          className="text-white font-semibold text-[12px] mb-1 md:mb-0"
        >
          Location:
        </label>
        <input
          type="text"
          id="location"
          name="location"
          onChange={handleFilterChange}
          value={filters.location}
          placeholder="Where would you love to stay?"
          className="w-full text-sm bg-green-500 text-white"
        />
      </div>
      <div className="md:border-light-green-500 md:pr-3 md:border-r">
        <label
          htmlFor="rooms"
          className="text-white font-semibold text-[12px] mb-1 md:mb-0"
        >
          Rooms
        </label>
        <input
          type="number"
          step="1"
          id="rooms"
          name="rooms"
          onChange={handleFilterChange}
          value={filters.rooms}
          placeholder="Number of rooms"
          className="w-full text-sm bg-green-500 text-white"
        />
   
      </div>
      <div className='pr-3'>
        <label
          htmlFor="duration"
          className="text-white font-semibold text-[12px] mb-1 md:mb-0"
        >
          Duration
        </label>
        <select
          id="duration"
          name="duration"
          className="w-full bg-transparent text-sm bg-transparent text-gray-400"
          value={filters.duration}
          onChange={handleFilterChange}
        >
          <option value="" disabled selected>
            Select duration (years)
          </option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          {/* Add more options as needed */}
        </select>
      </div>
      <button 
        className="flex bg-green-400/80 md:bg-transparent py-1 space-x-2 rounded-[20px] items-center justify-center"
        onClick={submitFilter}
      >
      <img
        src={Search}
        alt=""
        className="h-5 w-5 self-center"
      />
      <span className="md:hidden text-white">search</span>
      </button>
    </div>
  </div>
  )
}

export default ProductFilter