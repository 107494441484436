import React from "react";
import ProductFilter from "../RENTALS/product-filter";


const HeroVariationRentals = (props) => {
  return (
    <section className="w-full bg-green-500 xl:min-h-[28vh] ">
      <div className="px-10 pb-14 md:px-10 lg:px-4 flex flex-col lg:flex-col space-y-[60px] items-center py-[20px] md:pb-[60px]">
        <div className="flex items-center flex-col space-y-5">
          <p className="text-white font-bold font-sora text-5xl">
          Your Perfect Rental Space is Here
          </p>
          <p className="text-white font-sora font-semibold">
          Experience amazing spaces specially curated for the ultimate staycation or getaway.

          </p>
        </div>
        <div className="md:flex flex items-center">
        <ProductFilter filterProperties={props.filterProperties}/>

        </div>
      </div>
    </section>
  );
};

export default HeroVariationRentals;
